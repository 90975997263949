import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pkgInfo from '../package.json';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const {version} = pkgInfo;

function Footer () {
    return <>
        <Container fluid className="direccion">
            <Row className="bg-green">&nbsp;</Row>
            <Row className="bg-secondary text-white">
                <Container>
                    <Row className="py-4">
                        <Col lg={6}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Montevideo</h5>
                                <h6>SMART CLINIC: <br/>Isabelino Bosch 2529</h6>
                                <a href="mailto:uialergia@gmail.com">
                                    <FontAwesomeIcon icon="envelope" className="me-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="tel:‭27099624">
                                    <FontAwesomeIcon icon="phone" className="me-2" style={{width: '1em'}}/>
                                    2709 9624
                                </a>
                                <div className="clearfix"/>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12261.343244830576!2d-56.166504679011034!3d-34.90422349750764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8f22febf45c5341a!2sSmart%20Clinic!5e0!3m2!1ses-419!2suy!4v1592768925325!5m2!1ses-419!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"
                                    className="mt-3 w-100" title="Mapa Montevideo"/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Fray Bentos</h5>
                                <h6>Plaza Hotel: <br/>25 de mayo esquina 18 de julio</h6>
                                <a href="mailto:uialergia@gmail.com" className="py-5">
                                    <FontAwesomeIcon icon="envelope" className="me-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="https://wa.me/+59898159100">
                                    <FontAwesomeIcon icon={faWhatsapp} className="me-2" style={{width: '1em'}}/>
                                    098 159 100
                                </a>
                                <div className="clearfix"/>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d835.4298184252639!2d-58.312801170767784!3d-33.116463398804406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a54d88d16a7f3f%3A0xd0440a6c0787c554!2sPlaza%20Hotel%20Fray%20Bentos!5e0!3m2!1sen!2suy!4v1607124159022!5m2!1sen!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"
                                    className="mt-3 w-100" title="Mapa Fray Bentos"/>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Maldonado</h5>
                                <h6>Consultorio Parada 10: <br/>Rambla Dr. Claudio Williman parada 10 <br/> Playa Mansa
                                    - Edificio Isla de Gorriti</h6>
                                <a href="mailto:uialergia@gmail.com">
                                    <FontAwesomeIcon icon="envelope" className="me-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="https://wa.me/+59893304898">
                                    <FontAwesomeIcon icon={faWhatsapp} className="me-2" style={{width: '1em'}}/>
                                    093 304 898
                                </a>
                                <div className="clearfix"/>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3270.356133134642!2d-54.95080558815366!3d-34.939115375176215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9575052e11878063%3A0xb73c2105cb819212!2sConsultorios%20m%C3%A9dicos%20parada%2010!5e0!3m2!1sen!2suy!4v1731371657387!5m2!1sen!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"
                                    className="mt-3 w-100" title="Mapa Maldonado"/>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Colonia</h5>
                                <h6>SIMC: <br/>Manuel de Lobo 332<br/>&nbsp;</h6>
                                <a href="mailto:uialergia@gmail.com" className="py-5">
                                    <FontAwesomeIcon icon="envelope" className="me-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="https://wa.me/+59898159100">
                                    <FontAwesomeIcon icon={faWhatsapp} className="me-2" style={{width: '1em'}}/>
                                    098 159 100
                                </a>
                                <div className="clearfix"/>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d402.3394296418731!2d-57.847913268234464!3d-34.47255026674502!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3126800300595%3A0xe9ae5718c59160d1!2sSIMC%20Emergencia%20M%C3%B3vil!5e0!3m2!1ses-419!2suy!4v1731450029877!5m2!1ses-419!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"
                                    className="mt-3 w-100" title="Mapa Carmelo"/>

                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="p-1 text-center">
                                <h5 className="mb-2">Carmelo</h5>
                                <h6>Amelí: <br/>Sarandí 246<br/>&nbsp;</h6>
                                <a href="mailto:uialergia@gmail.com" className="py-5">
                                    <FontAwesomeIcon icon="envelope" className="me-2" style={{width: '1em'}}/>
                                    uialergia@gmail.com
                                </a>
                                <br/>
                                <a href="https://wa.me/+59898159100">
                                    <FontAwesomeIcon icon={faWhatsapp} className="me-2" style={{width: '1em'}}/>
                                    098 159 100
                                </a>
                                <div className="clearfix"/>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.68647252598!2d-58.283130400000005!3d-34.0005861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a4b3a9b3239ad3%3A0xf6847215b6627982!2sAmel%C3%AD%20-%20La%20Salita!5e0!3m2!1sen!2suy!4v1731408299952!5m2!1sen!2suy"
                                    frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"
                                    className="mt-3 w-100" title="Mapa Carmelo"/>

                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between text-center my-4">
                        <Col sm={12}>
                            Copyright © {(new Date()).getFullYear()} Todos los derechos reservados
                            <br/>
                            <small>Version: {version}</small>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    </>
}

export default Footer