import React, {useState} from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";

function Offer() {

    const [showConsultas, setShowConsultas] = useState(false);
    const [showDiagnostico, setShowDiagnostico] = useState(false);
    const [showTratamiento, setShowTratamiento] = useState(false);
    const [showMateriales, setMateriales] = useState(false);
    const [moreOffer, setMoreOffer] = useState(false);

    return <>
        <section id="servicios">
            <Container className="my-5" >
                <Row className="mb-4">
                    <Col lg={12}>
                        <h2 className="text-center uia_green mb-4">¿Que ofrecemos?</h2>
                        <p className="text-center mx-5">
                            Somos un equipo de profesionales especializados en el estudio y tratamiento de las enfermedades
                            alérgicas. <br/>
                            Apuntamos al abordaje integral y multidisciplinario del paciente, a través de la evaluación,
                            diagnóstico y tratamiento de su patología, entendiendo la afectación de su calidad de vida y
                            tratando al paciente como un TODO, incluyendo una estrategia preventiva.
                            <br/>
                            {!moreOffer && <a href="#asdf" className="small" onClick={(evt) => {
                                evt.preventDefault();
                                setMoreOffer(!moreOffer)
                            }}>+ informacion</a>}
                        </p>
                        {moreOffer && <p className="text-center mx-5">
                            Priorizamos la relación médico-paciente para que cada paciente y su familia entienda y aprenda
                            sobre su enfermedad crónica y como poder manejarla a futuro. <br/>
                            Hacemos especial énfasis en la educación, prevención e investigación de las enfermedades
                            alérgicas.Nuestro principal objetivo es mejorar la calidad de vida de los pacientes, brindando
                            una atención médica de calidad con vía a la excelencia. <br/>
                            ELEGIMOS LOS MEJORES TRATAMIENTOS BASADO EN EVIDENCIA CLÍNICA COMPROBADA con las últimas
                            actualizaciones.
                        </p>}
                    </Col>
                </Row>

                <Row>
                    <Col xl={3} lg={6} className="mb-4">
                        <div className="offers uno">
                            <h5 className="text-center text-white p-2">CONSULTAS</h5>
                        </div>

                        <p className="mb-1">
                            Atendemos pacientes PEDIÁTRICOS y ADULTOS de todo Uruguay ya sea modalidad presencial (
                            Montevideo, Maldonado, Fray Bentos) o por videoconsulta.
                        </p>
                        <a href="#asdf" className="small" onClick={(evt) => {
                            evt.preventDefault();
                            setShowConsultas(!showConsultas)
                        }}>+ informacion</a>
                    </Col>
                    <Col xl={3} lg={6} className="mb-4">
                        <div className="offers dos">
                            <h5 className="text-center text-white p-2">DIAGNÓSTICO</h5>
                        </div>
                        <p className="mb-1">
                            Mediante una detallada historia clínica y la realización de las pruebas complementarias
                            adecuadas que incluyen pruebas cutáneas (prick test, intradermorreacción), test de provocación
                            nasal con alérgenos y fármacos, análisis de sangre, evaluación de la función pulmonar en
                            conjunto con especialistas de otras áreas (espirometría broncodilatadora, prueba de
                            broncoprovocación inespecífica), evaluación de obstrucción nasal y el test de exposición
                            controlada a fármacos y alimentos, podemos establecer un diagnóstico.</p>
                        <a href="#asdf" className="small" onClick={(evt) => {
                            evt.preventDefault();
                            setShowDiagnostico(!showDiagnostico)
                        }}>+ informacion</a>
                    </Col>
                    <Col xl={3} lg={6} className="mb-4">
                        <div className="offers tres">
                            <h5 className="text-center text-white p-2">TRATAMIENTO</h5>
                        </div>
                        <p className="">
                            Tratamientos a largo plazo individualizado (MEDICINA DE PRECISIÓN), seguimiento y control
                        </p>
                        <ul className=" mb-1">
                            <li>Tratamiento preventivo.</li>
                            <li>Inmunoterapia específica</li>
                            <li>Tratamiento farmacológico</li>
                            <li>Tratamiento de la alergia a los alimentos.</li>
                            <li>Desensibilización a fármacos</li>
                            <li>Tratamientos no farmacológicos complementarios</li>
                        </ul>
                        <a href="#asdf" className="small" onClick={(evt) => {
                            evt.preventDefault();
                            setShowTratamiento(!showTratamiento)
                        }}>+ informacion</a>
                    </Col>
                    <Col xl={3} lg={6} className="mb-4">
                        <div className="offers cuatro">
                            <h5 className="text-center text-white p-2">MATERIAL PARA PROFESIONALES DE LA SALUD</h5>
                        </div>
                        <p className="mb-1">

                            ESTIMADOS COLEGAS: Entendemos que al ser una especialidad nueva en la UdelaR , en la carrera de
                            medicina no se enseña los motivos de referencia, creemos importante hacerles saber que no solo
                            presentamos esta lista de derivaciones sino que además estamos disponibles para consultas sobre
                            sus pacientes en caso necesario, tanto por email como al teléfono. Siempre tratando de trabajar
                            en equipo con el médico que realiza la referencia.</p>
                        <a href="#asdf" className="small" onClick={(evt) => {
                            evt.preventDefault();
                            setMateriales(!showMateriales)
                        }}>+ informacion</a>                </Col>
                </Row>
            </Container>
        </section>

        <Modal show={showConsultas} onHide={() => {
            setShowConsultas(false)
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>CONSULTAS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Consultas presenciales:</h4>
                <p>Llama a nuestros teléfonos de contacto para concertar fecha y hora de tu
                    consulta</p>
                <h4>Videoconsultas:</h4>
                <ul>
                    <li> Adaptados a la nueva realidad de distanciamiento social, todo el equipo médico de la Unidad
                        Integral de Alergia está accesible a través de video consulta, por lo que podrás concertar
                        tu cita con el médico de tu preferencia.
                    </li>
                    <li> Flexibilidad horaria: horarios de consulta más amplios que se pueden adaptar a tus
                        necesidades profesionales y personales.
                    </li>
                    <li> Ahorra tiempo: El acceso a la videoconsulta es rápido y sencillo por lo que ahorrarás
                        tiempo en esperas y desplazamientos.
                    </li>
                </ul>


                <h4> Consultas de pacientes con:</h4>
                <ul>
                    <li>ASMA Bronquial o tos crónica</li>
                    <li> RINITIS (estornudos, picazón, obstrucción nasal)
                    </li>
                    <li> DERMATITIS ATÓPICA(afecciones de piel)
                    </li>
                    <li> DERMATITIS DE CONTACTO</li>
                    <li> URTICARIAS (ronchas e hinchazón )</li>
                    <li>ALERGIA E INTOLERANCIA ALIMENTARIA</li>
                    <li>ALERGIA A MEDICAMENTOS o VACUNAS</li>
                    <li>ANAFILAXIA</li>
                    <li>ALERGIA A VENENO DE INSECTOS (abeja, avispa, hormigas)</li>
                </ul>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    setShowConsultas(false)
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>


        <Modal show={showDiagnostico} onHide={() => {
            setShowDiagnostico(false)
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>PROCEDIMIENTOS DIAGNÓSTICOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>PRICK TEST HABITUALES (Test de hipersensibilidad inmediata):</h4>
                <ul>
                    <li>Alergenos inhalantes y alimentarios</li>
                    <li>Alergenos inhalantes sólo</li>
                    <li>Alergenos alimentarios PRICK TO PRICK</li>
                    <li>Látex y/o test de uso al látex</li>
                    <li>Familia látex (sd alergia oral)</li>
                    <li>Venenos de insectos (Hymenópteros) y mosquito</li>
                </ul>
                <h4>IGE ESPECÍFICA MOLECULAR (295 MOLÉCULAS) ALEX 2</h4>

                <h4>PRICK TEST A MEDICAMENTOS:</h4>
                <ul>
                    <li>Medicamentos general (anestésicos, antibióticos, analgésicos)</li>
                    <li>Fluoresceína 10%</li>
                    <li>Medios de Contraste Radiológicos</li>
                </ul>
                <h4>TEST DE PROVOCACIÓN A DROGAS</h4>
                <h4>TEST DE SUERO AUTÓLOGO</h4>
                <h4>TEST DE PARCHE (Test de hipersensibilidad tardía)</h4>
                <ul>
                    <li>Serie estándar CHEMOTECHNIQUE</li>
                    <li>Serie de cosméticos CHEMOTECHNIQUE</li>
                    <li>Serie alimentaria</li>
                    <li>Serie sólo Leches</li>
                    <li>Aditivos al látex</li>
                    <li>Antibióticos betalactámicos</li>
                    <li>Medicamentos general</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    setShowDiagnostico(false)
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>


        <Modal show={showTratamiento} onHide={() => {
            setShowTratamiento(false)
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>TRATAMIENTOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Tratamiento preventivo.</h4>
                <p>Conseguir controlar la enfermedad alérgica mediante la identificación del agente etiológico y la aplicación de medidas para evitar su exposición.</p>

                <h4>Inmunoterapia específica.</h4>
                <p> La identificación de la fuente alergénica no siempre permite su evitación. El diagnóstico etiológico permite en algunos casos aconsejar un tratamiento con inmunoterapia específica o "vacuna de alergia", que logra una mejor tolerancia a la exposición del alérgeno y la consiguiente mejoría del control de la enfermedad. <br/>
                Tipos: Inmunoterapia subcutánea y sublingual</p>

                <h4>INMUNOTERAPIA a aeroalergenos o veneno de himenópteros:</h4>
                <ul>
                    <li>Inmunoterapia subcutánea</li>
                    <li>Inmunoterapia sublingual</li>
                </ul>

                <h4>Tratamiento farmacológico.</h4>
                <p>El tratamiento farmacológico necesario para el control de la enfermedad alérgica es una de las bases de la estrategia terapéutica. Abordar un tratamiento de forma integral que incluya normas de prevención e inmunoterapia específica en los casos en los que esté indicada, permitirá una mayor eficacia y optimización del tratamiento farmacológico que revertirá en un mejor control de la enfermedad.</p>

                <h4>Tratamiento de la alergia a los alimentos.</h4>
                <p>Identificar los alimentos involucrados en las reacciones alérgicas permite dar los consejos dietéticos oportunos para una dieta "segura". El manejo del paciente a nivel nutricional y dietético es otra de las áreas de tratamiento de la alergia a alimentos. La posibilidad de alérgenos ocultos o de una transgresión dietética, conlleva instruir al paciente en la identificación de signos y síntomas de alarma así como a instruirlo en la autoadministración de medicación de rescate.</p>

                <h4>Desensibilización a fármacos.</h4>
                <p>Mediante las técnicas de desensibilización se logra en la mayoría de los pacientes la tolerancia del fármaco al que es alérgico. Situaciones especiales e individualizadas precisan en algunas personas de la administración de un fármaco determinado al no existir tratamiento alternativo. La desensibilización es la única estrategia de tratamiento en estos casos.</p>

                <h4>DESENSIBILIZACIÓN A DROGAS</h4>
                <p>
                    <ul>
                        <li>AAS</li>
                        <li>Quimioterapicos</li>
                        <li>Antibioticos</li>
                    </ul>
                </p>

                <h4>Tratamientos no farmacológicos complementarios:</h4>
                <p>Uso de medicina complementaria como apoyo al tratamiento convencional de las enfermedades alérgicas como acupuntura, ozonoterapia, terapia neural. Indicado en cada caso después de un análisis específico de cada paciente y ajustado a sus necesidades individuales, se ofrece como una herramienta más a los pacientes interesados.</p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    setShowTratamiento(false)
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>


        <Modal show={showMateriales} onHide={() => {
            setMateriales(false)
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>ESTIMADOS COLEGAS</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p><b>ANESTESIOLOGÍA:</b> Evaluamos pacientes con anafilaxia perioperatoria para diagnóstico oportuno y tratamientos alternativos seguros.</p>

                <p><b>DERMATOLOGÍA:</b> Evaluamos en conjunto pacientes con afecciones alérgicas de piel , realizamos test diagnósticos como test del parche .</p>

                <p><b>GASTROENTEROLOGÍA:</b> Evaluamos en conjunto alergia alimentaria con diagnóstico y tratamiento.</p>

                <p><b>IMAGENOLOGÍA:</b> Evaluamos alergia a medios de contraste radiológicos.</p>

                <p><b>INFECTOLOGÍA:</b> Evaluamos pacientes alérgicos inmunodeprimidos que requieran tratamiento con antibióticos profilácticos que hayan reaccionado previamente , se puede generar tolerancia por la desensibilización a ATB</p>

                <p><b>MEDICINA GENERAL / INTERNA / EMERGENTOLOGIA:</b> Evaluamos alergia a antibióticos / vacunas , evitando así mal uso de antibioticoterapia</p>

                <p><b>MEDICINA LABORAL:</b> Evaluamos enfermedades alérgicas vinculadas a la salud ocupacional.</p>

                <p><b>NEUMOLOGÍA:</b> Evaluamos asma alérgicas , con diagnóstico y tratamiento con inmunoterapia como indicado en GINA , diagnóstico de  alergia a Aspergillus en ABPA.</p>

                <p><b>OFTALMOLOGÍA:</b> se realizan test de alergia a gotas oftálmicas , a medios de contraste radiológico como la fluoresceína , y test de alergia a anestésicos locales. Se tratan en conjunto conjuntivitis alérgicas con el diagnóstico y tratamiento</p>

                <p><b>ONCOLOGÍA:</b> Evaluamos alergia a quimioterápicos y en casos indicados realizamos tratamientos de desensibilización a quimioterápicos que sean necesarios . ( se genera tolerancia al quimioterápico que previamente le dio alergia al paciente )</p>

                <p><b>OTORRINOLARINGOLOGÍA:</b> Evaluamos rinitis alérgicas , con diagnóstico y tratamiento de inmunoterapia si así lo requiere el paciente. Hacemos diagnóstico de alergia a AAS en pacientes con la triada de Samter , se podrá realizar tratamiento específico para dicha patología previniendo aparición de nuevos pólipos nasales ,</p>

                <p><b>PEDIATRÍA:</b> Evaluamos en conjunto pacientes con patología alérgica por ejemplo atópica , pacientes con alergia a medicamentos o vacunas, alergia alimentaria.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    setMateriales(false)
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>

}

export default Offer