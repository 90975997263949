import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Team () {
    const [cvmariana, showMariana] = useState(false);
    const [cvpao, showPao] = useState(false);
    const [cvfernando, showFernando] = useState(false);
    const [cvvictoria, showVictoria] = useState(false);
    const [cvveronica, showVeronica] = useState(false);
    const [cvclara, showClara] = useState(false);
    const [cvflorencia, showFlorencia] = useState(false);

    return <>
        <section id="equipo" className="bg-light py-4">
            <Container>
                <Row className="pb-4">
                    <Col>
                        <h2 className="text-center uia_green">Nuestro Equipo</h2>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6} sm={12} xs={12}>
                        <Card>
                            <img src="/team/mariana.jpeg" className="card-img-top" alt="DRA MARIANA BONIFACINO"/>
                            <Card.Body>
                                <h5 className="card-title text-center">DRA MARIANA BONIFACINO</h5>
                                <p className="card-text">
                                </p>
                                <div className="text-center">
                                    Doctora en medicina egresada en la UdelaR 2011
                                    <p className="mt-2">
                                        <a href="#mariana" onClick={(evt) => {
                                            evt.preventDefault();
                                            showMariana(!cvmariana);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p>
                                </div>

                                <ul>
                                    <li>Especialidad en Alergología en la UdelaR iniciada en 2015</li>
                                </ul>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <Card>
                            <img src="/team/paola.jpeg" className="card-img-top" alt="DRA PAOLA CAMACHO"/>
                            <Card.Body>
                                <h5 className="card-title text-center">DRA PAOLA CAMACHO</h5>
                                <p className="card-text">

                                </p>
                                <div className="text-center">
                                    Alergóloga egresada - UdelaR 2022 <br/>
                                    Doctora en medicina egresada en ELAM - Cuba 2008
                                    <p className="mt-2">
                                        <a href="#pao" onClick={(evt) => {
                                            evt.preventDefault();
                                            showPao(!cvpao);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} sm={12} xs={12}>
                        <Card>
                            <img src="/team/victoria.jpeg" className="card-img-top" alt="DRA VICTORIA MATTOS"/>
                            <Card.Body>
                                <h5 className="card-title text-center">DRA VICTORIA MATTOS</h5>
                                <p className="card-text">
                                </p><p className="card-text">
                            </p>
                                <div className="text-center">
                                    Doctora en Medicina egresada en la UdelaR en 2011.
                                    <p className="mt-2">
                                        <a href="#victoria" onClick={(evt) => {
                                            evt.preventDefault();
                                            showVictoria(!cvvictoria);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p></div>
                                <ul>
                                    <li>Especialidad en Alergología en la UdelaR iniciada en 2018</li>
                                    <li>Especialidad en Pediatría en la UdelaR egresada en 2015</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                        <Card>
                            <img src="/team/fernando.jpeg" className="card-img-top" alt="DR FERNANDO LARROSA"/>
                            <Card.Body>
                                <h5 className="card-title text-center">DR FERNANDO LARROSA</h5>
                                <p className="card-text">
                                </p><p className="card-text">
                            </p>
                                <div className="text-center">
                                    Doctor en medicina egresado de Facultad de Medicina del CLAEH 2013
                                    <p className="mt-2">
                                        <a href="#fernando" onClick={(evt) => {
                                            evt.preventDefault();
                                            showFernando(!cvfernando);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p></div>
                                <ul>
                                    <li>Especialidad en Alergología en la UdelaR iniciada en 2017</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                        <Card>
                            <img src="/team/veronica.jpeg" className="card-img-top" alt="DRA VERONICA GONZALEZ"/>
                            <Card.Body>
                                <h5 className="card-title text-center">DRA VERONICA GONZÁLEZ</h5>
                                <p className="card-text">
                                </p>
                                <div className="text-center">
                                    Doctora en Medicina egresada de ELAM Cuba 2010
                                    <p className="mt-2">
                                        <a href="#mariana" onClick={(evt) => {
                                            evt.preventDefault();
                                            showVeronica(!cvveronica);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p>
                                </div>

                                <ul>
                                    <li>Medica de familia egresada de la Universidad de Montevideo en 2016</li>
                                    <li>Especialidad de Alergología en la UdelaR iniciada en 2021</li>
                                </ul>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4} sm={12} xs={12}>
                        <Card>
                            <img src="/team/clara.jpeg" className="card-img-top" alt="LIC CLARA ELVERS"/>
                            <Card.Body>
                                <h5 className="card-title text-center">LIC CLARA ELVERS</h5>
                                <p className="card-text">
                                </p><p className="card-text">
                            </p>
                                <div className="text-center">
                                    Licenciatura en Nutrición (2016-2020). Universidad Católica del
                                    Uruguay - 2020
                                    <p className="mt-2">
                                        <a href="#victoria" onClick={(evt) => {
                                            evt.preventDefault();
                                            showClara(!cvclara);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p></div>
                                <ul>
                                    <li>Especializada en intolerancias y alergia alimentaria</li>
                                    <li>Especializada en deficit de DAO</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} sm={12} xs={12}>
                        <Card>
                            <img src="/team/florencia.jpeg" className="card-img-top" alt="MAG FLORENCIA RAINUSSO"/>
                            <Card.Body>
                                <h5 className="card-title text-center">MAG FLORENCIA RAINUSSO</h5>
                                <p className="card-text">
                                </p><p className="card-text">
                            </p>
                                <div className="text-center">
                                    Licenciada en Psicología - UdelaR 2013
                                    <p className="mt-2">
                                        <a href="#victoria" onClick={(evt) => {
                                            evt.preventDefault();
                                            showFlorencia(!cvflorencia);
                                        }}>
                                            <strong>CV Abreviado</strong>
                                        </a>
                                    </p></div>
                                <ul>
                                    <li>Magister en Psicoterapia de Adultos, Pareja y Familia con
                                        énfasis cognitivo-sistémico. - Universidad Católica del Uruguay 2023</li>
                                    <li>Capacitación en acompañamiento Psicológico en alergias alimentarias - 2023</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>

        <Modal show={cvmariana} onHide={() => {
            showMariana(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>DRA MARIANA BONIFACINO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>

                <h5>CURSOS DE CORTA DURACIÓN</h5>
                <ul>
                    <li>Advanced course in Basic and Clinical Immunology FOCIS (03/2019 - 03/2019) <br/>
                        32 horas Palabras Clave: Inmunología Investigación
                    </li>
                    <li>Curso online Asma en niños y adolescentes : diagnóstico, tratamiento y derivación (26/9/2018 a 20/11/2018 )Clínica pediatrica B UdelaR <br/>
                        30 horas Palabras Clave : pediatría , asma
                    </li>
                    <li>Curso Investigación Clínica-Buenas Prácticas Clínicas -Fundación SMU (5/2018-6/2018 ) <br/>
                        24 horas Palabra Clave : Investigación Clínica
                    </li>
                    <li>Alergologia Clínica e aerobiologia Caxias do Sul (01/2017 - 01/2017) <br/>
                        Sociedad Brasilera de Inmunología , Brasil
                        60 horas Palabras Clave: aerobiologia polen gramineas, Test de provocación nasal
                    </li>
                    <li>MICE metodología de investigación científica y estadísticas (01/2016 - 01/2016) <br/>
                        Sector Educación Superior/Público / Universidad de la República / Comisión Sectorial de
                        Investigación Científica - UDeLaR , Uruguay Palabras Clave: estadística investigación
                    </li>
                </ul>
                <h5>Miembro Junior de :</h5>
                <ul>
                    <li>Sociedad Uruguaya de Alergia e Inmunología</li>
                    <li>SLAAI- Sociedad Latinoamericana de Alergia, Asma e Inmunología</li>
                    <li>AAAAI - Academia Americana de Alergia, Asma e Inmunología</li>
                    <li>EAACI - Academia Europea de Alergia e Inmunología Clínica</li>
                </ul>
                <h5>Idiomas</h5>
                <ul>
                    <li>Lengua materna Español y Portugués</li>
                    <li>Nivel avanzado Inglés</li>
                    <li>Nivel medio Italiano</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showMariana(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={cvpao} onHide={() => {
            showPao(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>DRA PAOLA CAMACHO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>
                <ul>
                    <li>Curso online Asma en niños y adolescentes : diagnóstico, tratamiento y derivación (26/9/2018 a 20/11/2018) <br/>
                        Clínica pediatrica B UdelaR 30 horas Palabras Clave: pediatría, asma.
                    </li>

                    <li>MICE metodología de investigación científica y estadísticas (2015) <br/>
                        Sector Educación Superior/Público / Universidad de la República / Comisión Sectorial de
                        Investigación Científica - UDeLaR , Uruguay <br/>
                        Palabras Clave: estadística investigación.
                    </li>

                    <li>Asistencia Servicio Inmunología, Alergias y VIH del Hospital Clínico de la Universidad de Chile, Santiago de Chile.
                        (08-2019) <br/>
                        Palabras claves: Alergia alimentaria, diagnóstico en alergias medicamentosas, aeroalergenos, test de parche.
                    </li>

                    <li>Workshop Actualización en Desensibilización a Fármacos, Santiago de Chile, agosto de 2019. <br/>
                        Palabras claves: alergia medicamentosa, prick test, patch test, Desensibilización a fármacos.
                    </li>
                </ul>
                <h5>Miembro Junior de :</h5>
                <ul>
                    <li>Sociedad Uruguaya de Alergia e Inmunología</li>
                </ul>
                <h5>Idiomas</h5>
                <ul>
                    <li>Lengua materna Español</li>
                    <li>Nivel avanzado Inglés</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showPao(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={cvfernando} onHide={() => {
            showFernando(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>DR FERNANDO LARROSA</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>
                <p>Vive en la ciudad de Maldonado.</p>
                <p>Doctor en Medicina, egresado del CLAEH en 2013.</p>
                <p>Especialidad en Alergología iniciada en 2017</p>
                <h5>CURSOS:</h5>
                <ul>
                    <li>Curso intracongreso - Desensibilización a fármacos. XLI congreso anual de la Asociación Argentina de Alergia e Inmunología Clínica. Buenos Aires, Argentina – Agosto 2018.</li>
                    <li>Curso intracongreso: Enfermedades alérgicas en el adulto mayor. XLI congreso anual de la Asociación Argentina de Alergia e Inmunología Clínica. Buenos Aires, Argentina – Agosto 2018.</li>
                </ul>
                <h5>MEMBRESÍAS</h5>
                <ul>
                    <li>Miembro de la Sociedad Uruguaya de Alergia, Asma e Inmunología (SUAAI).</li>
                    <li>Miembro Junior de la Academia Europea de Alergia e Inmunología Clínica (EAACI).</li>
                    <li>Miembro de la Asociación Argentina de Alergia e Inmunología Clínica (AAAeIC).</li>
                </ul>
                <p>Se desempeña como Alergista en varias instituciones médicas de la región Este del país.</p>
                <p>Está en continua formación, asistiendo a cursos, congresos y jornadas de actualización nacionales e internacionales.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showFernando(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={cvvictoria} onHide={() => {
            showVictoria(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>DRA VICTORIA MATTOS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>
                <p>Curso de alergias alimentarias, Hospital Italiano Buenos Aires setiembre a noviembre 2020.</p>
                <p>Asistencia al Servicio de Alergología Pediátrica del Hospital Vilela, Rosario Argentina, agosto de 2022.</p>
                <p>Workshop SCAI, Inmunoterapia alérgeno específica en la era del diagnóstico molecular, Chile, 9 de setiembre 2022.</p>
                <h5>MEMBRESÍAS</h5>
                <ul>
                    <li>Miembro de la Asociación Argentina de Alergia e Inmunología Clínica (AAAeIC).</li>
                    <li>Socia de la Sociedad Uruguaya de Pediatría. Integrante del Comité de Inmunología, Alergia y Reumatología.</li>
                </ul>
                <h5>IDIOMAS</h5>
                <ul>
                    <li>Lengua materna Español y Portugués</li>
                    <li>Nivel avanzado Inglés</li>
                    <li>Nivel medio Francés</li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showVictoria(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={cvveronica} onHide={() => {
            showVeronica(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>DRA VERONICA GONZÁLEZ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>
                <p>Curso de metodología científica para posgrados 2022, centro de investigaciones biomédicas (CEINBIO, Facultad de Medicina, UdelaR) (abril. Julio 2022)</p>
                <p>Diplomado universitario internacional en alergología en atención primaria, Universidad Europea Miguel Cervantes, Valladolid duración de 400 horas (2023).</p>
                <p>Diplomatura en alergias alimentarias, Universidad Católica de Salta (UCASAL), provincia de Salta, República Argentina, 80 horas (mayo-noviembre 2024).</p>
                <p>Congreso virtual AAIBA (Asociación Alergia, Asma e Inmunología Buenos Aires)  (octubre de 2021).</p>
                <p>Congreso anual AAAeIC (asociación de alergia, asma e inmunología de Buenos Aires), Buenos Aires, Argentina. (agosto de 2024).</p>
                <p>Curso de posgrado de función pulmonar, Buenos Aires, Argentina (agosto 2024).</p>
                <p>Asma y alergia a fármacos, AAAeIC (asociación de alergia, asma e inmunología de Buenos Aires) Salta, Argentina (junio de 2024).</p>
                <p>II Jornadas regionales de inmunología clínica. Sociedad chilena de alergia e inmunología. Enfoque en alergias alimentarias. (junio de 2024).</p>
                <p>Actualización en alergia, Organización mundial de alergia. (julio de 2024) Ohio state university.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showVeronica(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={cvclara} onHide={() => {
            showClara(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    LIC CLARA ELVERS
                    <a href="https://wa.me/+59892454258" className="ms-4">
                        <FontAwesomeIcon icon={faWhatsapp} className="me-2" style={{width: '1em'}}/>
                        092 454 258
                    </a>
                    <a href="https://claraelversnutricion.com/" className="ms-4">Página Web</a>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>
                <p>Única nutricionista acreditada en Uruguay a través del International Institute of DAO deficiency.</p>

                <p>Curso Universitario de Especialización: Intolerancias y Alergias
                    Alimentarias Pediátricas (actualidad). Instituto de estudios de Salud y
                    Nutrición y Universidad Europea Miguel de Cervantes.</p>
                <p>Diplomado en Salud Materno Infantil (junio 2021-diciembre 2021).
                    Instituto Universitario Vive Sano Brasil (avalado por Ministerio de
                    Educación de Brasil) (modalidad a distancia, 349 hs. académicas).</p>
                <p>Formación en Alimentación complementaria y Baby Led Weaning
                    (noviembre 2020- marzo 2021). Escuela Argentina de Baby Led
                    Weaning (modalidad a distancia, 60 hs).</p>
                <p>Curso: Adicción alimentaria, teoría, clínica y abordaje terapéutico.
                    (julio 2020-diciembre 2020). Universidad Austral de Buenos Aires (a
                    distancia, 130 hs.).</p>
                <p>Soporte Vital Básico para profesionales de la salud (RCP, DEA y
                    maniobras de desobstrucción en lactantes, niños y adultos).
                    (octubre 2020) Universidad Católica del Uruguay, avalado por
                    American Heart Association.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showClara(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={cvflorencia} onHide={() => {
            showFlorencia(false);
        }} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    MAG FLORENCIA RAINUSSO
                    <a href="https://wa.me/+59894586980" className="ms-4">
                        <FontAwesomeIcon icon={faWhatsapp} className="me-2" style={{width: '1em'}}/>
                        094 586 980
                    </a>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Formación complementaria:</h4>
                <p>Entrenamiento Básico en EMDR en EMDR- Iberoamérica Montevideo, Uruguay
                    (Agosto 2022)</p>
                <p>Diplomatura en Mindfulness y Terapias de tercera generación en Instituto
                    Iberoamericano de Estudios para la Paz, España (2021).</p>
                <p>Posgrado: "Curso anual de Autismo y otros Trastornos del Desarrollo
                    Socioemocional - Diagnóstico, Clínica, Evaluación e Intervención" Grupo
                    CIDEP - Dr. Miguel A. Gracia Coto - Bs. As., Argentina (Abril- Diciembre 2018)</p>
                <p>Certificación en COACHING CON PNL, Instituto de Programación
                    Neurolingüística del Uruguay, Montevideo, Uruguay (2017)</p>
                <p>Programación Neurolingüística a cargo de la Psicóloga Mayra Alvez (Mayo –
                    Diciembre 2015)</p>
                <p>Taller de habilidades comunicativas dictado por la Psicóloga Mayra Alvez
                    Montevideo, Uruguay (2014)</p>
                <p>Curso profundización en Análisis Transaccional dictado por la Psicóloga
                    Mayra Alvez Montevideo, Uruguay (2014)</p>
                <p>Curso de profundización en Hipnosis Ericksoniana dictado por la Psicóloga
                    Mayra Alvez Montevideo, Uruguay (2014)</p>
                <p>Jornadas Internacionales TECTEA 2012 "Nuevas tecnologías aplicadas al
                    trastorno espectro autista"- Ciudad de Buenos Aires, Argentina. (Octubre 2012)</p>
                <p>"Segundo Simposio Internacional de Autismo: Comprensión Teórica e
                    Intervención Educativa" dictado por el Dr. Theo Peeters Ciudad de Tucumán,
                    Argentina. (Diciembre 2011)</p>
                <p>XIX Congreso anual y XXIII Curso de Posgrado de la Academia
                    Iberoamericana de Neurología Pediátrica - Buenos Aires, Argentina. (Octubre
                    2011)</p>
                <p>Congreso Internacional de "Autismo, Asperger y otros trastornos
                    generalizados del desarrollo TGD" - Montevideo Uruguay (Noviembre 2011)</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(evt) => {
                    evt.preventDefault();
                    showFlorencia(false);
                }}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}

export default Team;